/*
 * Copyright 2018 General Code
 */

.noteContainer .noteTitleComponent {
  margin-top: 0;
}
.noteContainer .noteTitleComponentInputInactive, .noteTitle /* loading fallback */ {
  color: #1e1e1e;
  /* Use pre-wrap so that all whitespace including newlines is rendered like it would be in an active title editor but still soft wrap if a line is too long*/
  white-space: pre-wrap;
}
.noteTitleComponentInactiveWrapper .noteTitleComponent, .noteTitleComponentInactiveWrapper .noteTitleComponentInput {
  margin-bottom: 0;
  padding-bottom: 0;
}

.noteMetadata {
  margin-top: 2px;
  margin-bottom: 12px;
}

.noteMetadataNoBottom {
  margin-top: 2px;
}

.noteContainerMinimized .noteMetadata {
  margin-bottom: 8px;
}


/* no js/loading fallback for title */
.noteTitle {
  min-height: 30px;
  font-size: 24px;
  font-family: freight-sans-pro, sans-serif;
  font-weight: 500;
  padding: 6px 0 7px;
  line-height: 1.1875em;
  margin-bottom: 8px;
}
