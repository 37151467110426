/*
 * Copyright 2019 General Code
 */

#relatedTermsLabel {
  font-size: 20px;
  font-weight: 500;
}

.relatedTermContainer {
  margin-top: 8px;
  padding-right: 5px;
}
.relatedTermsContainer {
  padding-bottom: 16px;
}

.relatedTermLink {
  text-decoration: none;
}
.relatedTermLink:hover {
  text-decoration: underline;
}