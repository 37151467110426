#notesTop {
  display: flex;
  align-items: center;
  min-height: 36px;
  margin-bottom: 8px;
}

.notesDescriptionContainer {
  flex: 1 1 50%;
}

.noteFilter {
  flex: 0 0 25%;
}

.noteFilter:empty {
  flex: 0 0 0;
  margin-left: 0;
}

@media (max-width: 759px) {
  .responsive #notesTop {
    display: block;
  }
}