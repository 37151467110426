#doc-bulk-upload-dialog tr.error {
  background-color: #FDEDED;
}

#doc-bulk-upload-dialog tr.warning {
  background-color: #FFF4E5;
}

#doc-bulk-upload-dialog tr.success {
  background-color: #EDF7ED;
}

#doc-bulk-upload-dialog .message {
  font-size: medium;
}

#doc-bulk-upload-dialog .message .error, #doc-bulk-upload-dialog .message.error, #doc-bulk-upload-dialog svg.error {
  font-weight: 600;
  color: #D32F2F;
}

#doc-bulk-upload-dialog .message .warning, #doc-bulk-upload-dialog .message.warning, #doc-bulk-upload-dialog svg.warning {
  font-weight: 600;
  color: #ED6C02;
}

#doc-bulk-upload-dialog .message .success, #doc-bulk-upload-dialog .message.success, #doc-bulk-upload-dialog svg.success {
  font-weight: 600;
  color: #2E7D32
}

.bulkUploader-documentTable-column.date-column, .bulkUploader-documentTable-column.title-column {
  /* A bit of a hack to be able to expand empty values to the full height */
  height: 1px;
}

.bulkUploader-documentTable-value.editable {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  cursor: pointer;
}

.tableLoadingHeader tr {
  height: auto !important;
}

.tableLoadingHeader tr th {
  padding: 0 !important;
}