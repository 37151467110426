/* GC Admin styling for Admin Settings Page */

#adminSettingsPage #eReaderOverlay {
  position: absolute;
  width: 680px;
  height: 200px;
  background-color: rgba(128, 128, 160, .7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.gcAdminSettings-section {
  margin-top: 8px;
  margin-bottom: 16px;
}

.gcAdminSettingsList {
  margin: 0;
}

.gcAdminSettingsList-CheckboxLabel {
  height: 32px;
}

#adminSettingsPage .formSelectControl, .adminSettingsPage-mapUrl {
  width: 100%;
}

#adminSettingsPage-mapUrl-container, #adminSettingsPage-zoningChapter-container {
  display: flex;
}

#adminSettingsPage-mapUrl-container .buttonGroup, #adminSettingsPage-zoningChapter-container .buttonGroup {
  align-self: center;
}

#adminSettingsPage-countiesContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 8px;
}

#adminSettingsPage-countiesContainer .Select {
  width: 100%;
  padding-top: 16px;
}

#adminSettingsPage-countiesContainer .Select-control {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border-width: 0 0 1px 0;
  border-color: rgba(0, 0, 0, 0.42);
  outline: 0;
  box-shadow: none;
  cursor: text;
  overflow: visible;
  background-color: transparent;
  padding: 3px 0 1px 0;
  z-index: 1;
}

#adminSettingsPage-countiesContainer .Select.is-focused .Select-control, #adminSettingsPage-countiesContainer .Select-control:hover {
  padding-bottom: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.87);
}

#adminSettingsPage-countiesContainer .Select--multi .Select-value {
  color: rgba(0, 0, 0, 0.87);
  height: 28px;
  cursor: default;
  border: none;
  display: inline-flex;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  align-items: center;
  border-radius: 16px;
  background-color: #e0e0e0;
  margin: 2px;
  padding: 0 2px 0 0;
}

#adminSettingsPage-countiesContainer .Select--multi .Select-value-icon {
  font-size: 24px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
  padding: 10px 0 12px 10px;
  border: none;
}

#adminSettingsPage-countiesContainer .Select--multi .Select-value-icon:hover {
  background-color: transparent;
  color: black;
}

#adminSettingsPage-countiesContainer .Select--multi .Select-value-label {
  color: rgba(0, 0, 0, 0.87);
  border-radius: 16px;
  padding: 0 12px 0 6px;
  font-size: 0.9285714285714285rem;
  font-family: "freight-sans-pro", sans-serif;
}

#adminSettingsPage-countiesContainer .Select--multi .Select-input {
  margin-left: 0;
}

#adminSettingsPage-countiesContainer .Select-placeholder {
  display: none;
}

#adminSettingsPage-countiesContainer .Select + .Select-label {
  padding: 0;
  -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,-webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  -o-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,-webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  color: rgba(0, 0, 0, 0.54);
  font-size: 1.1428571428571428rem;
  position: absolute;
  top: 20px;
  left: 0;
  z-index: 0;
}

#adminSettingsPage-countiesContainer .Select.is-focused + .Select-label, #adminSettingsPage-countiesContainer .Select.has-value + .Select-label {
  transform: translate(-10%, -22px) scale(0.75);
}

#adminSettingsPage-countiesContainer .Select.is-focused + .Select-label {
  color: rgba(0, 0, 0, 0.87);
}

#adminSettingsPage-countiesContainer .Select-menu-outer {
  z-index: 2;
  border-top: 1px solid rgba(0, 0, 0, 0.42);
}

#adminSettingsPage-countiesContainer .Select-option {
  min-height: 32px;
}

#adminSettingsPage-countiesContainer .Select-option:hover, #adminSettingsPage-countiesContainer .Select-option.is-focused {
  background-color: rgba(0, 0, 0, 0.07);
  border: none;
  outline: 0;
  box-shadow: none;
}

#adminSettingsPage-countiesContainer .Select--single .Select-value, #adminSettingsPage-countiesContainer .Select--single .Select-input {
  padding-left: 0;
}

#adminSettingsPage-countiesContainer .Select--single .Select-value-label {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.1428571428571428rem;
  font-family: "freight-sans-pro", sans-serif;
}

.adminSettingsPage-navigationCell {
  font-size: 12pt;
}

#adminSettingsPage-updateNavNames {
  margin-top: 8px;
}

.adminSettingsPage-navigationCellHeader {
  font-weight: bold;
}

#adminSettingsPage-addTextQuickLink {
  margin-top: 8px;
}