.split-button-container {
  display: flex;
}

.split-button-container .split-button {
  flex: 1 1 auto;
  border-radius: 4px 0 0 4px;
}

.split-button-container .split-button-dropdown-toggle {
  flex: 0 0 auto;
  border-radius: 0 4px 4px 0;
  min-width: auto;
}

