.filtersWidget .filterChip {
  margin: 0 4px 4px 0;
  max-width: 100%;
  overflow-x: hidden;
}

.filtersWidget .filterChip > span {
  display: block;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filtersWidget .filterChip > svg {
  flex: 0 0 24px;
}