.muiDialogPaper {
  position: relative;
}

.muiDialogTitle, .muiDialogActions {
  box-sizing: border-box;
  position: sticky;
  left: 0;
  background-color: #FAFAFA;
  width: 100%;
  z-index: 2;
}

.muiDialogTitle {
  top: 0;
}

.muiDialogActions {
  bottom: 0;
  width: calc(100% - 8px);
  margin: 0;
  padding-bottom: 8px;
}