.topCategoryPaneRow {
  display: flex;
  align-items: center;
}

.topCategoryPaneRow .categoryName {
  flex: 1 1 auto;
}

.categoryTitleContainer {
  flex: 1 1 auto;
  display: flex;
}

.pubDocCategoryTitle.mediumTitle {
  display: flex;
  align-items: center;
  word-break: break-word;
  padding: 8px;
  margin-left: 6px;
  margin-top: 0;
}

a.pubDocCategoryTitle.mediumTitle {
  text-decoration: none;
}

a.pubDocCategoryTitle.mediumTitle:hover, .pubDocTitle a.smallTitle:hover {
  text-decoration: underline;
}

.pubDocsLandingPage .pubDocCategoryTitle.mediumTitle {
  margin-left: 0;
  padding-left: 0;
}

.pubDocCategoryVisiblityIcon {
  margin-left: auto;
  margin-right: 8px;
  color: #666;
  align-self: center;
}

.pubDocCategoryEditButton {
  float: right;
  margin-right: 8px;
}

.pubDocCategoryDescriptionContainer {
  margin-left: 6px;
  margin-top: -8px;
  padding-left: 8px;
}

.pubDocCategoryDescription {
  word-break: break-word;
}

.pubDocCategoryContainer {
  min-height: 30px;
}

.pubDocCategoryCount {
  font-weight: normal;
  color: #888;
  font-size: 90%;
  align-self: center;
  margin: 0 6px;
}