.pubDocCategoryEditTitle {
  width: 100%;
}

.pubDocCategoryEditDescription {
  width: 100%;
}


.pubDocCategoryEditDialogContent {
  overflow: visible;
}

.pubDocCategoryEditDialogContent.loading {
  min-height: 40vh;
}

.pubDocCategoryEditForm {
  margin-right: 8px;
}