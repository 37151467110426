.addPubDocTypeButton {
  height: 24px;
  width: 24px;
  margin-left: 2px;
}

.addPubDocTypeButton svg {
  height: 20px;
  width: 20px;
}

#doc-type-edit-dialog .dialogContent {
  padding-top: 4px;
}

#doc-type-edit-dialog input {
  width: 100%;
}

.suggestionForm {
  position: relative;
}

.suggestionContainer .hidden {
  visibility: hidden;
  max-height: 0;
  overflow: hidden;
  transition: visibility 1s, max-height 0.5s ease-out;
}

.suggestionContainer {
  z-index: 10;
  width: 100%;
  position: absolute;
  top: 58px;
  left: 0;
  text-shadow: none;
}

.suggestionContainer.hidden {
  display: none;
}

.suggestionContainerLabel {
  padding-top: 12px;
  line-height: normal !important;
}

.suggestionContainerLabel:focus {
  outline: none;
}

.suggestionItem {
  padding: 4px 16px !important;
  font-size: .91rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.selected.suggestionItem {
  color: white;
  background-color: #49A942;
}

.pubDocTypes {
  margin-left: -24px;
  margin-right: -24px;
}

.pubDocTypes .pubDocType {
  padding-left: 24px;
  padding-right: 24px;
}