.noteColorControls {
  position: relative;
  display: inline-block;
}

.noteEditControls-colorPicker .colorList {
  padding: 0;
  background-color: #fff;
}

.picker .pickerButton-color {
  padding: 2px 20px 2px 15px;
  border-radius: 2px;
}

.noteEditControls-colorPicker .pickerButtonPrimary {
  line-height: 1;
  font-weight: 500;
}

.noteEditControls-colorPicker .pickerButtonSecondary {
  line-height: 1;
}

.picker .pickerMenu-color {
  width: 200px;
}

.pickerMenu-color .pickerItem-color,
.picker .pickerItem-color.pickerButton-color {
  border-left-width: 20px;
  border-left-style: solid;
}

