.stickyCloseContainer {
  top: 0;
  position: sticky;
  background-color: var(--paper-background-color);
  display: flex;
  justify-content: flex-end;
  padding: 4px;
  margin-left: -4px;
  margin-right: -4px;
  z-index: 1;
}

.responsiveContentPanelDrawerContents {
  padding: 24px 8px;
}

.stickyCloseContainerLabel {
  flex: 1 1 auto;
  margin-top: -4px;
}
