.picker {
  position: relative;
  display: inline-block;
}

.picker .pickerList {
  padding: 0;
  background-color: #fff;
}

.picker .pickerButton {
  padding: 2px 0 2px 15px;
  /* same height as a material outlined button given the extra pixel of shadow the paper has*/
  min-height:35px;
}

.picker .pickerItem {
  border-left-width: 4px;
  border-left-style: solid;
}



.picker .pickerButtonPrimary {
  line-height: 1;
  font-weight: 500;
}

.picker .pickerButtonSecondary {
  line-height: 1;
}

.pickerPicker .pickerMenu {
  width: 200px;
}

