/* Banner customization styling for Admin Settings Page */

#adminSettingsPage #bannerDropzoneContainer {
  font-size: 16px;
}

#adminSettingsPage #bannerDropzoneContainer ul {
  margin-left: 3px;
}

#adminSettingsPage .bannerDropzone {
  height: 115px;
  border: 2px dashed rgb(102, 102, 102);
  border-radius: 5px;
  padding: 10px;
  background-color: #fff;
  cursor: pointer;
}

.bannerCropperPaper {
  width: 900px;
  max-width: 900px !important;
}

#adminSettingsPage #croppedBanner {
  width: 678px;
  cursor: pointer;
}

