.pubDocEditDialogContent {
  overflow: visible;
  position: relative;
}

.pubDocEditDialogContent.loading {
  min-height: 50vh;
}

.pubDocEditDialogTypeSelect {
  width: 100%;
}

.pubDocEditDialogFileSelect {
  display: none;
}

.pubDocEditDialogErrorText {
  color: red;
}

.pubDocEditDialogTypeLabel {
  padding-right: 8px;
}

.pubDocEditDialogCategorySelect {
  width: 100%;
}

.pubDocEditDialogNameField {
  width: 100%;
}

.pubDocEditDialogDescriptionField {
  width: 100%;
}

.pubDocEditDialogDateField {
  width: 100%;
}

.pubDocEditDialogMetadataLabel {
  padding-right: 8px;
}