
.noteEditControls {
  /* Need to do it with a two row grid so that the buttons can align on baseline but also be on the bottom "row" leaving the labels to the top */
  display: grid;
  grid: "color display visibility visibility .      .    " 18px
        "color display visibility visibility cancel save" 36px
        /104px 104px   auto       144px      64px   64px;
  grid-column-gap: 8px;
  margin-top: 8px;
  margin-bottom: 16px;
  align-items: baseline;
}

.noteEditControls-colorPicker {
  grid-area: color;
}

.noteEditControls-displayModePicker {
  grid-area: display;
}

.noteEditControls-visibilityPicker {
  grid-area: visibility;
}

.noteEditControls-cancelWrapper {
  grid-area: cancel;
  align-self:baseline;
}

.noteEditControls-saveWrapper {
  grid-area: save;
  align-self:baseline;
}

@media (max-width: 759px) {
  .responsive .noteEditControls {
    display: none;
  }
}
