.colorPicker .color {
  width: 36px;
  height: 14px;
  border-radius: 2px;
}

.colorPicker .swatch {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  display: inline-block;
  cursor: pointer;
}

.colorPicker .popover {
  position: absolute;
  z-index: 2;
}

.colorPicker .cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}