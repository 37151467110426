#advancedSearchDialog .rowContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#advancedSearchDialog .row, #advancedSearchDialog .row .fieldContainer {
  display: flex;
  align-items: flex-end;
}

#advancedSearchDialog .checkbox.row, #advancedSearchDialog .checkbox.row .fieldContainer {
  display: flex;
  align-items: flex-start;
}

#advancedSearchDialog .headingRow.row {
  margin-bottom: 8px;
}

#advancedSearchDialog .rowGroup {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

#advancedSearchDialog .row .column1 {
  display: flex;
  flex: 1 1 50%;
  align-items: flex-end;
}

#advancedSearchDialog .row .column2 {
  display: flex;
  flex: 1 1 50%;
  align-items: flex-end;
  margin-left: 16px;
}

#advancedSearchDialog .fieldContainer {
  width: 100%;
  display: flex;
  align-items: flex-end;
}

#advancedSearchDialog .child .field, #advancedSearchDialog .child .heading {
  margin-left: 16px;
}

#advancedSearchDialog .child .child .field, #advancedSearchDialog .child .child .heading {
  margin-left: 32px;
}

#advancedSearchDialog .textField.row .field, #advancedSearchDialog .description {
  margin-top: 8px;
}

#advancedSearchDialog .checkbox.row .description {
  margin-top: 0;
  margin-bottom: 8px;
}

#advancedSearchDialog .example {
  color: #666;
  font-family: monospace;
  font-size: 14px;
  font-weight: 600;
}

#advancedSearchDialog .divider {
  margin: 40px 0 32px 0;
}

#advancedSearchDialog .shown {
  visibility: visible;
  max-height: 500px;
  transition: visibility 1s, max-height 1.0s ease-in;
}

#advancedSearchDialog .hidden {
  visibility: hidden;
  max-height: 0;
  overflow: hidden;
  transition: visibility 1s, max-height 0.5s ease-out;
}

#advancedSearchDialog .clear {
  width: 30px;
  height: 30px;
  flex: 0 0 30px;
}

#advancedSearchDialog .checkboxLabel {
  align-items: flex-end;
}

#advancedSearchDialog .checkbox.row .field {
  padding-top: 4px;
  padding-bottom: 0;
}

#advancedSearchDialog .textField.row .field {
  width: 100%;
}

#advancedSearchDialog .textField.row .half.field {
  width: 50%;
}

#advancedSearchDialog .fieldContainer .label {
  color: rgba(0, 0, 0, 0.54);
}

#advancedSearchDialog #advancedSearchSlop .distance.field {
  width: 128px;
}

#advancedSearchDialog #advancedSearchSlop .distance.field input {
  text-align: center;
}

#advancedSearchDialog .field[aria-describedby] + .clear {
  align-self: center;
}

#advancedSearchQueryString {
  display: block;
  font-weight: normal;
  font-size: 1.2rem;
}

@media (max-width: 995px) {
  .responsive #advancedSearchDialog .row .column1 {
    flex: 0 0 100%;
  }
  .responsive #advancedSearchDialog .description {
    display: none;
  }
}

.advancedActionButtons {
  position: absolute;
  bottom: 8px;
  right: 8px;
}