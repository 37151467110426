
.addReferenceDropdown {
  min-width: 164px;
}

.addReferenceIcon {
  padding-right: 4px;
}

.addReferenceWrapper .rdw-dropdown-optionwrapper {
  width: 100%;
  overflow-y: auto;
}

.addReferenceModalPaper {
  width: 900px;
  max-width: 900px !important;
}

.addReferenceModalPaper,
.addReferenceModalContents {
  overflow-y: visible !important;
}

.addReferenceSelect {
  width: 100%;
  z-index: 10;
}

.addReferenceTitle {
  font-size: 16px;
  font-weight: 600;
}

.addReferenceExampleLabel {
  margin-top: 25px;
  font-weight: 500;
}

.addReferenceTreeContainer {
  width: 100%;
  height: 158px;
  overflow: auto;
}

.stepActionContainer {
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  margin-top: 25px;
}

.stepActionContainer button {
  margin-left: 10px;
}

.addReferenceSelect .VirtualizedSelectOption span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.overflowingStep {
  overflow: visible !important;
  animation: 319ms delay-overflow;
}

@keyframes delay-overflow {
  from { overflow: hidden; }
}

.addReferenceSeparatorText {
  font-size: 1.5em;
  font-weight: bold;
  margin: 25px 0px;
}
