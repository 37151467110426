.pubDocTypeTitleDescription {
  white-space: pre-wrap;
}

.suggestionItem.disabled {
  cursor: default;
  opacity: 0.5;
}

.suggestionItem .typeExists {
  opacity: 0.6;
}

.dialogRow {
  display: flex;
  width: 100%;
  margin-top: 4px;
}

.dialogRow.flex > * {
  flex: 1 1 auto;
}

.dialogRow.flex > * + * {
  margin-left: 8px;
}

.dialogRow + .dialogRow {
  margin-top: 16px;
}

.fileContainer {
  flex: 1 1 auto;
}

.outlined-control, .outlined-date-picker input {
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.23);
}

.outlined-control:hover, .outlined-date-picker input:hover {
  border-color: rgba(0, 0, 0, 0.87);
}

.outlined-control *:before, .outlined-control *:after, .outlined-date-picker *:before, .outlined-date-picker *:after {
  display: none;
}

.outlined-control-label, .outlined-date-picker label {
  background-color: white;
  padding: 0 4px;
  margin-top: -1px;
  z-index: 1;
}

.outlined-date-picker input {
  padding: 12px;
}

.outlined-date-picker label {
  margin: 8px;
}

.outlined-control-select {
  padding: 0 12px 12px 12px;
  cursor: pointer;
}

.outlined-control input + svg {
  margin-top: -2px;
  margin-right: 4px;
}

.fileInputLabel {
  margin-left: 8px;
}

.spinner {
  position: absolute;
  top: 38%;
  left: 42%;
}

.pubDocTitleContainer {
  flex: 1 1 auto;
  display: flex;
  box-sizing: border-box;
  padding: 4px;
  cursor: default;
}

.pubDocsLandingPage .pubDocTitleContainer {
  min-height: 40px;
}

.pubDocTitle {
  margin-right: 8px;
  pointer-events: auto;
  word-break: break-word;
  flex: 1 1 auto;
}

.pubDocRow {
  content-visibility: auto;
}

.pubDocRow.pubDocRowExpanded {
  display: flex;
  align-items: center;
  margin: 8px;
  contain-intrinsic-size: 1px 74px;
}

.pubDocRow.pubDocRowCollapsed {
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 4px;
  contain-intrinsic-size: 1px 40px;
}

.pubDocRowContent {
  margin: 0 0;
  display: flex;
  flex-grow: 1;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
}

.pubDocRowHeader {
  pointer-events: none;
}

.pubDocDate {
  margin-right: 8px;
}

.pubDocRowContent > .pubDocRowButton {
  flex: 0 0 30px;
  pointer-events: auto;
  padding-right: 0;
}

.pubDocRowButton:last-of-type {
  margin-right: 8px;
}

.pubDocIcon {
  pointer-events: auto;
  color: #666;
  align-self: center;
}

.pubDocExpandCollapseIcon {
  pointer-events: auto;
}

.pubDocCheckbox {
  pointer-events: auto;
  padding: 8px;
}

.pubDocVisibilityIcon {
  margin-right: 8px;
  color: #666;
  align-self: center;
}

.pubDocExpansionPanelDetails {
  flex-direction: column;
  margin-left: 28px;
  padding-bottom: 16px;
}

.pubDocYearRow {
  font-weight: bold;
  margin-left: 6px;
  padding-left: 8px;
}

.appBarSticky.pubDocAppBar {
  background-color: var(--paper-background-color);
  margin-bottom: 16px;
}

.pubDocToolbar {
  align-items: center;
}

.pubDocToolbarFiltersContainer {
  flex: 1 1 auto;
  display: flex;
}

.pubDocToolbar .pubDocCategoryForm {
  margin-right: 8px;
  align-self: baseline;
}

.pubDocToolbar .pubDocYearForm {
  margin-right: 8px;
  width: 30%;
  align-self: baseline;
}

.pubDocToolbar .pubDocMonthForm {
  margin-right: 8px;
  width: 35%;
  align-self: baseline;
}

.pubDocToolbar .pubDocDayForm {
  margin-right: 8px;
  width: 30%;
  align-self: baseline;
}

.pubDocToolbarButtonContainer {
  flex: 0 0 auto;
  display: flex;
}

.pubDocToolbarButton {
  white-space: nowrap;
  min-width: max-content;
  margin-right: 8px;
  margin-left: auto;
}

.pubDocActionsButton {
  padding-right: 8px;
}

.pubDocEditDialogTitle {
  display: flex;
}

.pubDocEditDialogTitle > span:first-child {
  flex: 1 1 auto;
}

.pubDocBulkUploaderButton {
  flex: 0 0 auto;
}

.pubDocToolbarCategorySticky {
  display: none;
}

.pubDocSectionContainer {
  padding-bottom: 16px;
}

.pubDocCategoryPaper {
  min-height: 46px;
}

.pubDocYearPaper {
  display: flex;
  align-items: center;
}

.pubDocExpansionPanel {
  width: 100%;
}

.pubDocExpansionPanelSummary {
  padding: 0 0 0 0;
  pointer-events: none;
}

.pubDocsLandingPage .pubDocExpansionPanelSummary {
  pointer-events: auto;
}

.pubDocModalText {
  margin-bottom: 8px;
}

.pubDocModalList {
  list-style-type: disc;
}

.pubDocModalListItem {
  margin-left: 24px;
}

#gpsCategoryText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0;
}