
#autoSuggestContainer {
  z-index: 10;
  width: 100%;
  position: absolute;
  top: 41px;
  left: 0;
  text-shadow: none;
}

#autoSuggestContainer.hidden {
  display: none;
}

.autoSuggestSubheader {
  padding-top: 12px;
  line-height: normal !important;
}

.autoSuggestSubheader:focus {
  outline: none;
}

.quickJumpItem {
  padding: 4px 16px !important;
  font-size: .91rem;
}

.quickJumpItem .codeReference {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.quickJumpItem .codeReference a {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.quickJumpItem .referenceContext, .quickJumpItem .referenceTitle {
  font-size: .91rem
}