.fileDropzone {
  box-sizing: border-box;
  width: 100%;
  border: 2px dashed rgb(102, 102, 102);
  border-radius: 5px;
  padding: 10px;
  background-color: #fff;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 8px;
}

.removeFileButton {
  margin-left: 4px;
}

.filePreviewArea.multiple {
  overflow-y: auto;
  border: 1px solid gray;
  border-radius: 4px;
  padding: 4px 8px;
  margin: 6px 4px 16px 4px;
}

.fileUploader-errorDialog ol {
  list-style-type: auto;
  padding-left: 16px;
}