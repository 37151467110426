#translateAcknowledgement {
  margin-top: 8px;
}

#translateAcknowledgementRequired {
  margin-top: 16px;
  color: darkred;
}

#translateAcknowledgementActions {
  margin-top: 16px;
}

