.questionOptionInput {
  padding: 8px;
}

.questionFlexDiv {
  display: flex;
}

.questionOptionTextDiv {
  padding-top: 8px;
}

.optionText {
  width: 98%;
  margin-bottom: 12px;
  margin-top: 0;
}

.optionSubOptions {
  margin-left: 48px;
  margin-top: 6px;
}

.additionalOptionContent.inline {
  display: inline;
}

.optionFileContainer {
  display: inline-block;
  margin-left: 8px;
}

.optionFile {
  display: none;
}

.optionFileList {
  margin-left: 12px;
}