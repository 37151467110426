/* Color customization styling for Admin Settings Page */

#adminSettingsPage #colorPreviewPane {
  margin-top: 16px;
  display: inline-block;
  width: 50%;
}

#adminSettingsPage #previewPage {
  height: 150px;
  width: 300px;
  background-color: #fff;
  margin-right: 10px;
  border-top: 3px solid #5b5b5b;
  border-bottom: 32px solid #5b5b5b;
  border-left: 1px solid #333;
  border-right: 1px solid #333;
}

#adminSettingsPage #previewHeader {
  height: 26px;
  border-bottom: 10px solid #5b5b5b;
}

#adminSettingsPage #previewContent {
  margin-left: auto;
  margin-right: auto;
  width: 235px;
}

#adminSettingsPage #previewNav {
  display: inline-block;
  width: 70px;
}

#adminSettingsPage #previewNav li {
  border-left: 5px solid #fff;
  padding-left: 5px;
}

#adminSettingsPage #previewTitle {
  display: inline-block;
  vertical-align: top;
}

#adminSettingsPage #previewBullet {
  display: inline-block;
  margin-right: 5px;
  height: 8px;
  width: 8px;
  border-radius: 4px;
}

#adminSettingsPage #colorInputPane {
  margin-top: 16px;
  display: inline-block;
  width: 50%;
  vertical-align: top;
}

#adminSettingsPage .colorPicker {
  display: inline-block;
  margin-right: 10px;
}

#adminSettingsPage .colorSelection label {
  vertical-align: top;
}

#adminSettingsPage .colorSelection {
  margin-bottom: 5px;
}
