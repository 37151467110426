@media print {
  body {
    min-height: auto !important;
    position: static !important;
    overflow-x: initial !important;
  }

  #warning {
    display: none;
  }
}