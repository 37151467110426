#adminSettingsPage-apiAdmin {
  font-variant-numeric: proportional-nums lining-nums;
}

.adminSettingsPage-apiAdmin-labelColumn-form, .adminSettingsPage-apiAdmin-labelColumn-container {
  display: flex;
}

.adminSettingsPage-apiAdmin-labelColumn-field {
  flex: 1 1 100%;
}

.adminSettingsPage-apiAdmin-keyColumn-value, .adminSettingsPage-apiAdmin-labelColumn-value {
  margin: auto 0;
  padding-top: 24px;
  padding-bottom: 15px;
}

.adminSettingsPage-apiAdmin-labelColumn-save, .adminSettingsPage-apiAdmin-labelColumn-edit {
  flex: 0 0 30px;
}

.adminSettingsPage-apiAdmin-labelColumn-save {
  align-self: flex-end;
  margin-bottom: 8px;
}

.adminSettingsPage-apiAdmin-labelColumn-container {
  cursor: pointer;
}

.adminSettingsPage-apiAdmin-labelColumn-edit {
  display: none;
  align-self: center;
  margin-left: 8px;
}

.adminSettingsPage-apiAdmin-labelColumn-container:hover .adminSettingsPage-apiAdmin-labelColumn-edit,
.adminSettingsPage-apiAdmin-labelColumn-container:focus .adminSettingsPage-apiAdmin-labelColumn-edit {
  display: block;
}

th[align='center'] {
  text-align: center;
}

.adminSettingsPage-apiAdmin-dialogActions {
  margin-top: 16px;
}

.adminSettingsPage-apiAdmin-snippetContainer {
  position: relative;
  line-height: initial;
  display: flex;
  border: 1px solid #d3d3d3;
  border-radius: 1px;
  cursor: default;
  padding: 4px 4px 4px 8px;
  transition: all cubic-bezier(0.4,0.0,0.2,1) .1s;
}
.adminSettingsPage-apiAdmin-snippetContainer:focus {
  border-color: #3b78e7;
}

.adminSettingsPage-apiAdmin-snippet {
  flex: 1 1 100%;
  align-self: center;
}

.adminSettingsPage-apiAdmin-copy {
  flex: 0 0 30px;
}

.adminSettingsPage-apiAdmin-keyWarning {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.warningIcon {
  fill: #ffa000
}