.rc-slider-tooltip {
  z-index: 1300;
}

.rc-slider-tooltip-inner {
  padding: 6px;
  height: 28px;
  font-size: 14px;
  font-variant-numeric: proportional-nums lining-nums;
}

.rangeValueLabel {
  color: rgba(0, 0, 0, 0.54);
}