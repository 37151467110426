.questionReferences .questionReferenceContent {
  font-style: italic;
}

.questionReferenceLabel .titleLink:hover .titleNumber, .questionReferenceLabel .titleLink:hover .titleTitle {
  text-decoration: underline;
}

.questionSelectedText.highlight, .eCodeSelection {
  display: inline;
  background-color: #FFFF99;
}