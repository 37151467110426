.lawTableTopContainer {
  display: flex;
  justify-content: flex-end;
}

.lawTableSearch {
  margin-bottom: 16px !important;
}

.lawTablePaper {
  background-color: #f4f4f4;
}

.reactTable thead tr {
  height: 36px;
}

.reactTable td, .reactTable th {
  padding: 4px 8px 4px 16px !important;
}

.reactTable td {
  font-size: 1rem !important;
}

.reactTable th {
  font-size: .9rem !important;
}

.tableDetailRow {
  height: 32px !important;
  padding-top: 0 !important;
}

.tableDetailRow td {
  padding: 0 8px 8px 32px !important;
}

.tableDetailRow div {
  font-style: italic;
  font-size: .9rem;
}

.adoptedLawCell, .dispositionXref {
  white-space: nowrap;
}

.titleLawCell {
  display: inline-block;
}

.titleLawCell .smallPdfSvg {
  float: right;
}