
.checkboxListFacetOption {
  margin: 8px 0;
}

.checkboxListFacetOptionCheckbox, .checkboxListFacetOptionGroupCheckbox {
  padding: 0 8px 0 12px;
}

.filterExpansionPanel {
  margin-right: 16px;
}

.filterExpansionPanelSummary {
  padding: 0 12px;
}

.filterExpansionPanelSummary .filterExpansionPanelSummaryExpanded:not([role="button"]) {
  margin: 12px 0;
}

.filterExpansionPanelSummary.filterExpansionPanelSummaryExpanded {
  min-height: 48px;
}

.filterExpansionPanelDetails {
  flex-direction: column;
  padding: 0 12px 12px;
}

.filterExpansionPanelButton {
  right: 0;
}

.checkboxListFacetOptionGroup.grouped .checkboxListFacetOption {
  margin-left: 12px;
}