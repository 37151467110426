.helpIcon {
  height: 24px;
  width: 24px;
  fill: grey;
}

.helpIconButton:hover > * > * {
  fill: #1e1e1e;
}

svg.pdfIcon {
  height: 24px;
  width: 24px;
}
