.editLawDialogPaper {
  width: 600px;
  max-width: 600px;
}

.editLawDialogField {
  margin-bottom: 16px !important;
}

.useCustomTitleLabel {
  font-size: 1rem !important;
  margin-bottom: -4px;
}

.updatedInfoLine {
  font-size: 12px;
  font-weight: 500;
  color: #999;
  line-height: 1;
}

.editLawDialogPaper .bottomContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-top: 16px;
}

.editLawDialogTitle h2 {
  display: flex;
  justify-content: space-between;
}

.formData {
  margin: 2px 0;
  font-size: 1.15rem;
}

.textDisabled {
  color: rgba(0, 0, 0, .38) !important;
}

.formSection:not(:first-child) {
  margin-top: 8px;
}

.editLawHelp {
  position: absolute;
  right: 0;
  margin-right: 20px;
}