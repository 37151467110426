.loader {
  display: block;
  width: 140px;
  margin: 0 auto;
}

.isSticky {
  z-index: 10;
  padding: 0;
}

.isSticky#searchTopContainer {
  background-color: #fff;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
  padding-top: 0;
}

.isSticky #scopeSelectorContent {
  width: 222px;
}

.isSticky#searchTopContainer, .isSticky#scopeSelectorContainer {
  top: 0 !important;
}

.isSticky#searchTopContainer, .isSticky#scopeSelectorContainer {
  top: 0 !important;
}

html.goog-tbar .isSticky#searchTopContainer, html.goog-tbar .isSticky#scopeSelectorContainer {
  top: 40px !important;
}

body.has-warning .isSticky#searchTopContainer, body.has-warning .isSticky#scopeSelectorContainer {
  top: 40px !important;
}

body.versioned .isSticky#searchTopContainer, body.versioned .isSticky#scopeSelectorContainer {
  top: 48px !important;
}

body.menubar-is-sticky .isSticky#searchTopContainer, body.menubar-is-sticky .isSticky#scopeSelectorContainer {
  top: 42px !important;
}

html.goog-tbar body.has-warning .isSticky#searchTopContainer, html.goog-tbar body.has-warning .isSticky#scopeSelectorContainer {
  top: 80px !important;
}

html.goog-tbar body.versioned .isSticky#searchTopContainer, html.goog-tbar body.versioned .isSticky#scopeSelectorContainer {
  top: 88px !important;
}

html.goog-tbar body.menubar-is-sticky .isSticky#searchTopContainer, html.goog-tbar body.menubar-is-sticky .isSticky#scopeSelectorContainer {
  top: 82px !important;
}

body.has-warning.versioned .isSticky#searchTopContainer, body.has-warning.versioned .isSticky#scopeSelectorContainer {
  top: 90px !important;
}

body.has-warning.menubar-is-sticky .isSticky#searchTopContainer, body.has-warning.menubar-is-sticky .isSticky#scopeSelectorContainer {
  top: 84px !important;
}

body.versioned.menubar-is-sticky .isSticky#searchTopContainer, body.versioned.menubar-is-sticky .isSticky#scopeSelectorContainer {
  top: 90px !important;
}

html.goog-tbar body.has-warning.versioned .isSticky#searchTopContainer, html.goog-tbar body.has-warning.versioned .isSticky#scopeSelectorContainer {
  top: 130px !important;
}

html.goog-tbar body.has-warning.menubar-is-sticky .isSticky#searchTopContainer, html.goog-tbar body.has-warning.menubar-is-sticky .isSticky#scopeSelectorContainer {
  top: 124px !important;
}

html.goog-tbar body.versioned.menubar-is-sticky .isSticky#searchTopContainer, html.goog-tbar body.versioned.menubar-is-sticky .isSticky#scopeSelectorContainer {
  top: 130px !important;
}

body.has-warning.versioned.menubar-is-sticky .isSticky#searchTopContainer, body.has-warning.versioned.menubar-is-sticky .isSticky#scopeSelectorContainer {
  top: 132px !important;
}

@media (max-width: 995px) {
  .responsive#searchResults .sidebarArea .isSticky {
    z-index: 0;
  }

  .responsive.expandedMenu .sidebarArea > div:first-child > div:first-child {
    padding-bottom: 0 !important;
  }

  .responsive .sidebarArea #scopeSelectorContainer.isSticky {
    max-height: 0;
    overflow: hidden;
  }

  .responsive.expandedMenu .sidebarArea #scopeSelectorContainer.isSticky {
    max-height: initial;
  }

  .responsive#searchResults .sidebarArea .stickyContainer > div:first-child > div:first-child {
    display: none;
  }

  .responsive#searchResults .sidebarArea .isSticky #scopeSelectorContent {
    width: auto;
  }
}

@media (max-width: 995px) and (min-width: 760px) {
  .responsive.expandedMenu .sidebarArea #scopeSelectorContainer.isSticky {
    position: static !important;
  }
}

@media (max-width: 759px) {
  .responsive#searchResults #searchForm .isSticky {
    z-index: 0;
  }

  .responsive#searchResults #searchForm .stickyContainer > div:first-child > div:first-child {
    display: none;
  }

  .responsive#searchResults #searchForm .isSticky#searchTopContainer {
    position: static !important;
    width: auto !important;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .responsive#searchResults #scopeSelectorContainer.isSticky {
    position: static !important;
  }
}

.cardTitleSmall {
  color: #333;
  font-weight: 600;
  font-size: 18px;
  margin: 4px 0;
}


