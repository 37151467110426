.resultNumber.icc {
  background-color: #0B5940;
}

.resultTagLine svg {
  margin-right: 8px;
}

.moreActions {
  float: right;
}

.resultAffectsLinks {
  margin-bottom: 8px;
}

.resultAffectsLinksLabel {
  margin-right: 8px;
  font-variant-ligatures: no-common-ligatures;
  font-size: .91rem;
}

.resultAffectsLink {
  margin-right: 10px;
  color: #333;
  text-decoration: none;
  font-weight: 600;
  font-variant-numeric: proportional-nums lining-nums;
}

.resultAffectsLink:hover {
  text-decoration: underline;
}