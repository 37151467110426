.menubarStickyShim #menubarContainer {
  width: 100%;
}

@media (min-width: 760px) and (max-width: 995px) {
  .responsive.menubar-is-sticky .sidebarArea {
    position: fixed;
    margin-top: 0;
  }
}

@media (max-width: 759px) {
  .responsive #mainStickyContainer > div:first-child > div:first-child {
    padding-bottom: 42px;
  }
}
