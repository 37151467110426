.Select-multi-value-wrapper, .Select-input {
  height: 100%;
}

.Select-value {
  display: flex;
  align-items: center;
}

.Select-input > input {
  box-sizing: border-box !important;
  width: 100% !important;
  height: 100%;
}