.reactButtonWrapper {
  display: inline-block;
}

.reactButtonWrapper span {
  text-shadow: none;
}

#actionButtonsPageControl .reactButtonWrapper + .reactButtonWrapper {
  display: inline-block;
  margin: 0 0 8px 2px;
}