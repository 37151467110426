/*
 * Copyright 2018 General Code
 */
.notes {
  margin: 16px 0 16px 0;
  display: grid;
  grid-row-gap: 8px;
}

.notes:empty {
  display: none;
}

.notes .note {
  width: 100%;
  padding-left: 23px;
  padding-right: 8px;
  border-left-width: 7px;
  border-left-style: solid;
  position: relative;
  background-color: var(--paper-background-color);
  border-radius: 4px;
}

.noteContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.noteBorder-accent, .noteBorder-default {
  border-left-color: var(--accent-color);
}

.noteBorder-banner {
  border-left-color: var(--banner-color);
}

.noteBorder-title {
  border-left-color: var(--accent-text-color);
}

.noteBorder-mint {
  border-left-color: #01a6b9;
}
.noteBorder-teal {
  border-left-color: #095c66;
}
.noteBorder-roc {
  border-left-color: #404040;
}
.noteBorder-berry {
  border-left-color: #620055;
}
.noteBorder-pineapple {
  border-left-color: #ccc000;
}

.note:first-child {
  margin-top: 0;
}


.noteDetail {
  font-size: 12px;
  font-weight: 500;
  color: #999;
  line-height: 1;

}


.rdw-image-imagewrapper > img, .noteContent > img, .noteHtml img {
  max-width: 100%;
}

option[value="red"] {
  background-color: darkred;
  color: white;
}

option[value="blue"] {
  background-color: darkblue;
  color: white;
}

option[value="green"] {
  background-color: darkgreen;
  color: white;
}

.note-loading {
  position: relative;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  margin-left: 32px;
}

.note-loading:before {
  position: absolute;
  left: -32px;
  top: 50%;
  width: 32px;
  height: 32px;
  background: url("./assets/note-editing.svg") 0 0 / 20px 24px no-repeat;
  opacity: 0.26;
  content: "";
  display: block;
}

.note-loading .noteTitle {
  color: #1e1e1e;
  cursor: default;
  font-size: 24px;
  min-height: 30px;
  font-weight: 500;
  padding: 6px 0 0;
  margin-bottom: 0;
  white-space: pre-wrap;
}

.note-loading .noteInfo {
  margin-top: 2px;
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: 500;
  color: #999;
  line-height: 1;
}

.noteHtml > ul {
  margin-top: 16px;
  margin-right: 0;
  margin-bottom: 0;
}

.noteHtml > ul > li {
  list-style-type: disc;
  margin-top: 1em;
}

.noteHtml > ul > ul > li {
  list-style-type: circle;
  margin-top: 1em;
}

.noteHtml li {
  list-style-type: square;
}

.noteHtml ul {
  margin-left: 1.5em
}

.selectedNotePageControl {
  background-color: #eee;
}

.noteLocationHeader {
  font-size: 20px;
}

.notePageControlsList {
  margin: 0;
  padding-bottom: 8px;
  display: block;
}
